import axios from "axios"

export class ServicoPraca {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Praca` //process.env.VUE_APP_URL_TRIG_GESTOR
  }
  buscarPracas() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  buscarPorIdFuncionario(idFuncionario) {
    return axios({
      url: this.urlServico + `/GetByIdFuncionario?idFuncionario=${idFuncionario}`,
      method: "GET"
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  salvarPraca(id, praca, idFuncionario, dataVinc) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(
        this.urlServico + "/Salvar",
        { id: id, nomePraca: praca, codFuncionario: idFuncionario, dataVinculo: dataVinc },
        config
      )
      .catch(error => {
        alert("Erro ao salvar um novo cargo " + error)
        return Promise.reject(error)
      })
  }
}
