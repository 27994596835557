<template>
  <v-select
    autocomplete
    label="Funcionario"
    placeholder="Selecione..."
    :rules="[() => !!codFuncionario || 'Campo obrigatório']"
    v-model="codFuncionario"
    :items="funcionarios"
    item-value="id"
    @input="setSelected"
    item-text="nome"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoFuncionario } from "../../servicos/servicoFuncionario"
const servicoFuncionario = new ServicoFuncionario()
export default {
  data: () => ({
    funcionarios: [],
    codFuncionario: ""
  }),
  created() {
    serverBus.$on("carregarFuncionarioSelecionado", value => {
      this.codFuncionario = value
    })

    servicoFuncionario.buscarFuncionario().then(
      res => {
        if (res.status === 200) {
          this.funcionarios = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("funcionarioSelected", value)
    }
  }
}
</script>
