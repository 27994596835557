export default class ProdutosRomaneio {
  constructor(id = "", idRomaneio = "", codProduto = "", quantidade = "", codigoBarras = [], idSacola = null) {
    this.id = id
    this.idRomaneio = idRomaneio
    this.codProduto = codProduto
    this.quantidade = quantidade
    this.codigoBarras = codigoBarras
    this.idSacola = idSacola
  }
}
