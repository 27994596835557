import axios from "axios"

export class ServicoRomaneio {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Romaneio`
  }
  buscarRomaneios() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  buscarRomaneiosSelect() {
    return axios({
      url: this.urlServico + `/GetRomaneioSelect`,
      method: "GET"
    })
  }
  salvar(romaneio) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", romaneio, config).catch(error => {
      alert("Erro ao salvar um novo romaneio " + error)
      return Promise.reject(error)
    })
  }
  salvarDatas(id, novaDataSaidaRomaneio, dataSaidaRomaneio, novaDataRetornoRomaneio, dataRetornoRomaneio, switch1) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(
        this.urlServico + "/SalvarDatas",
        {
          id,
          novaDataSaidaRomaneio,
          dataSaidaRomaneio,
          novaDataRetornoRomaneio,
          dataRetornoRomaneio,
          finalizado: switch1
        },
        config
      )
      .catch(error => {
        alert("Erro ao salvar um novo romaneio " + error)
        return Promise.reject(error)
      })
  }
  devolverItensRomaneio(id) {
    return axios.get(this.urlServico + `/DevolverItensRomaneio?id=${id}`, { timeout: 60000 }).catch(error => {
      alert("Erro ao salvar um novo romaneio " + error)
      return Promise.reject(error)
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  imprimir(id) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios({
      url: this.urlServico + `/RomaneioPDF?id=${id}`,
      method: "GET",
      responseType: "blob",
      ...config // important
    }).then(response => {
      var blob = new Blob([response.data], { type: "application/pdf" })
      var link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      var fileName = `Romaneio_${Date.now()}.pdf`
      link.download = fileName
      link.click()
    })
  }
  buscarProdutoPorCodigoBarraNoRomaneio(codigo, codRomaneio) {
    return axios({
      url: this.urlServico + `/BuscarProdutoPorCodigoBarraNoRomaneio?idRomaneio=${codRomaneio}&codigoBarra=${codigo}`,
      method: "GET"
    })
  }
  salvarConferencia(romaneio) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/SalvarConferencia", romaneio, config).catch(error => {
      alert("Erro ao salvar um novo romaneio " + error)
      return Promise.reject(error)
    })
  }
}
