<template>
  <v-autocomplete
    autocomplete
    label="Praça"
    placeholder="Selecione..."
    v-model="codPraca"
    :items="pracas"
    item-value="id"
    @input="setSelected"
    item-text="nomePraca"
    required
  ></v-autocomplete>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoPraca } from "../../servicos/servicoPraca"
const servicoPraca = new ServicoPraca()
export default {
  data: () => ({
    idFuncionario: null,
    pracas: [],
    codPraca: ""
  }),
  created() {
    serverBus.$on("funcionarioSelected", value => {
      this.idFuncionario = value

      servicoPraca.buscarPorIdFuncionario(this.idFuncionario).then(
        res => {
          if (res.status === 200) {
            this.pracas = res.data
          }
        },
        // eslint-disable-next-line
        err => console.log(err)
      )
    })
    if (!this.idFuncionario) {
      servicoPraca.buscarPracas().then(res => {
        if (res.status === 200) {
          this.pracas = res.data
        }
      })
    }

    serverBus.$on("carregarPracaSelecionada", value => {
      this.codPraca = value
    })
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("pracaSelected", value)
    }
  }
}
</script>
