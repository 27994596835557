export default class Romaneio {
  constructor(
    id = "",
    idFuncionario = "",
    idPraca = "",
    dataSaida = "",
    dataRetorno = "",
    finalizado = false,
    produtos = []
  ) {
    this.Id = id
    this.IdFuncionario = idFuncionario
    this.IdPraca = idPraca
    this.DataSaida = dataSaida
    this.DataRetorno = dataRetorno
    this.Finalizado = finalizado
    this.Produtos = produtos
  }
}
